type HeaderWrapperProps = {
  children: React.ReactNode;
};

export const HeaderWrapper = ({ children }: HeaderWrapperProps) => {
  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      {children}
    </div>
  );
};
