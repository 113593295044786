import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import { createRoutesFromElements } from 'react-router';
import { Handbook } from '../../views/Handbook';
import { RequestLink } from '../../views/auth/RequestLink';
import { RequestNewLink } from '../../views/auth/RequestNewLink';
import { Token } from '../../views/auth/Token';
import { EmailSent } from '../../views/auth/EmailSent';
import { ErrorBoundary } from '../../views/ErrorBoundary';
import { Logout } from '../../views/auth/Logout';
import { userLoader } from '../../loaders/userLoader';
import * as Sentry from '@sentry/react';
import { Acknowledge } from '../../views/Acknowledge';
import { handbookLoader } from '../../loaders/handbookLoader';
import { NotFound } from '../../views/NotFound';
import { WelcomeInterstitial } from '../../views/WelcomeInterstitial';

export const routes = (
  <Route errorElement={<ErrorBoundary />}>
    <Route index element={<Navigate to="handbook" replace />} />
    <Route path="login" element={<RequestLink />} />
    <Route path="logout" element={<Logout />} loader={Logout.loader} />
    <Route
      path="request-new-link"
      element={<RequestNewLink />}
      loader={RequestNewLink.loader}
    />
    <Route path="token" element={<Token />} loader={Token.loader} />
    <Route
      path="check-your-email"
      element={<EmailSent />}
      action={EmailSent.action}
    />
    <Route element={<Outlet />} path="handbook" id="user" loader={userLoader}>
      <Route
        path="welcome"
        element={<WelcomeInterstitial />}
        action={WelcomeInterstitial.action}
      />
      <Route loader={handbookLoader} element={<Outlet />} id="handbook">
        <Route index element={<Handbook />} />
        <Route
          path="acknowledge"
          element={<Acknowledge />}
          action={Acknowledge.action}
        />
        <Route path=":policySectionId" element={<Handbook />} />
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Route>
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(routes),
);

export const Router = () => <RouterProvider router={router} />;
