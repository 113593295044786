import { UserIcon } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { Link } from 'react-router-dom';

export const UserMenu = () => {
  return (
    <DropdownMenu
      isIconButton
      ariaButtonText="Open user menu"
      buttonContent={
        <UserIcon className="mt-1.5 size-8 rounded-full border border-sage-600 p-1 text-gray-500" />
      }
    >
      <MenuItem key="Sign out" as={Link} to="/logout">
        Sign Out
      </MenuItem>
    </DropdownMenu>
  );
};
