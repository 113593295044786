import { OpenNavButton } from './OpenNavButton';
import { Link } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { useHandbook } from '../../hooks/useHandbook';
import { UserMenu } from './UserMenu';
import { HandbookTitle } from './HandbookTitle';
import { HeaderWrapper } from './HeaderWrapper';

export const HandbookHeader = () => {
  const { is_acknowledged } = useHandbook();
  return (
    <HeaderWrapper>
      <OpenNavButton />
      <HandbookTitle />
      <div className="flex items-center">
        {!is_acknowledged && (
          <>
            <p className="mr-6 hidden text-sm font-semibold text-zinc-700 md:block">
              Your signature is required
            </p>
            <div className="mr-4">
              <Button as={Link} to="/handbook/acknowledge">
                Sign now
              </Button>
            </div>
          </>
        )}
        <UserMenu />
      </div>
    </HeaderWrapper>
  );
};
