import {
  LoaderFunctionArgs,
  json,
  redirect,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { NarrowWrapper } from '../../components/application/NarrowWrapper';
import { AuthFooter } from './AuthFooter';

type LoaderData = {
  token: string;
  email?: string;
};

const INTENT = 'request-new-link' as const;

export const RequestNewLink = () => {
  const { email, token } = useLoaderData() as LoaderData;
  // const fetcher = useFetcher();
  const { state } = useNavigation();
  const submit = useSubmit();

  const onSubmit = () => {
    submit(
      { token, email: email || null, intent: INTENT },
      {
        method: 'POST',
        encType: 'application/json',
        action: '/check-your-email',
        navigate: true,
      },
    );
  };

  return (
    <NarrowWrapper>
      <div>
        <h1 className="mb-2 text-4xl font-black tracking-tight text-zinc-800">
          This link has expired
        </h1>
        <p className="text-zinc-800">
          The link you followed is no longer valid. You may request to have a
          new link sent to{' '}
          {email ? <strong>{email}</strong> : 'your email address'}.
        </p>
      </div>
      <Button
        onClick={onSubmit}
        isLoading={state !== 'idle'}
        disabled={state !== 'idle'}
      >
        Send me a new link
      </Button>
      <AuthFooter />
    </NarrowWrapper>
  );
};

RequestNewLink.loader = async ({ request }: LoaderFunctionArgs) => {
  const urlSearchParams = new URL(request.url).searchParams;
  const token = urlSearchParams.get('token');
  if (!token) {
    return redirect('/login');
  }

  return json({
    email: urlSearchParams.get('email'),
    token,
  });
};

RequestNewLink.intent = INTENT;
