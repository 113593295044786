import { FunctionComponent } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { useUser } from '../hooks/useUser';

export const HandbookUnavailable: FunctionComponent = () => {
  const { legal_entity } = useUser();
  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-xl p-8 py-12">
        <div className="flex items-center">
          <div className="flex min-h-full items-center justify-center">
            <article className="flex grow items-center justify-center">
              <div className="flex grow flex-col items-center justify-center gap-y-6">
                <InformationCircleIcon className="size-12 self-center text-zinc-700" />

                <div className="w-full space-y-2 text-center">
                  <h2 className="text-2xl font-bold tracking-tight">
                    Your employee handbook is not available
                  </h2>

                  <p className="text-base">{`${legal_entity.name} has not published a handbook yet`}</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
