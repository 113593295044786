import { HandbookTitle } from '../components/application/HandbookTitle';
import { HeaderWrapper } from '../components/application/HeaderWrapper';
import { UserMenu } from '../components/application/UserMenu';
import graphic from '../assets/book-butterfly.png';
import { Button } from '@mosey/components/buttons/Button';
import { LegalLinks } from '@mosey/components/navigation/LegalLinks';
import { Form, redirect, useNavigation } from 'react-router-dom';
import { api } from '../utils/api';

export const WelcomeInterstitial = () => {
  const { state } = useNavigation();

  return (
    <div className="flex min-h-screen flex-col">
      <HeaderWrapper>
        <HandbookTitle />
        <UserMenu />
      </HeaderWrapper>
      <div className="flex flex-col items-center p-8">
        <img src={graphic} alt="" className="w-full max-w-[235px]" />
        <Form
          className="flex max-w-md flex-col"
          method="POST"
          action="/handbook/welcome"
        >
          <h2 className="mt-10 text-center text-2xl font-bold tracking-tight">
            Access your workplace policies
          </h2>
          <p className="mb-6 mt-1 text-center text-zinc-700">
            Acme is using Mosey to give you clear and up-to-date policies
            tailored to your location. Know your rights, understand your
            responsibilities, and stay updated effortlessly.
          </p>
          <Button isFullWidth type="submit" isLoading={state !== 'idle'}>
            View handbook
          </Button>
          <LegalLinks />
        </Form>
      </div>
    </div>
  );
};

WelcomeInterstitial.action = async () => {
  await api.post({ url: '/api/handbook/acknowledge_legal_notice', body: {} });
  return redirect('/handbook');
};
