import { FunctionComponent } from 'react';
import dog404 from '../assets/404-dog.svg';

export const NotFound: FunctionComponent = () => {
  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md p-8 py-12">
        <div className="flex items-center">
          <h2 className="mt-6 text-center text-4xl font-extrabold tracking-tight text-gray-900">
            Not found
          </h2>
          <img src={dog404} alt="Not found" />
        </div>
      </div>
    </div>
  );
};
