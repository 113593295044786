import {
  CategoryListItem,
  Policy as TPolicy,
} from '../../types/policy-handbook';
import { Policy } from './Policy';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';
import { RegionScopedContent } from '@mosey/components/handbook/RegionScopedContent';
import { PolicyTitle } from './PolicyTitle';
import { CompanyWidePolicyContent } from '@mosey/components/handbook/CompanyWidePolicyContent';
import { useMemo } from 'react';
import { fillPolicyContent } from '@mosey/utils/templates/render';

const AllRegionContent = ({ policy }: { policy: TPolicy }) => {
  const { main, full_details: fullDetails } = useMemo(
    () => fillPolicyContent(policy.content, { '-1': policy.attribute_values }),
    [policy],
  );
  return (
    <div className="space-y-2">
      <RegionScopedContent
        mainContent={main}
        regions={
          policy.policy_scope?.region?.name
            ? [policy.policy_scope?.region?.name]
            : []
        }
      />
      {fullDetails && <PolicyFullDetails fullDetails={fullDetails} />}
    </div>
  );
};

type PolicyCategoryProps = {
  category: CategoryListItem;
};

export const PolicyCategory = ({
  category: { customPolicy, regionPolicy, slug, title },
}: PolicyCategoryProps) => {
  if (!customPolicy && !regionPolicy) {
    return null;
  } else if (!(customPolicy && regionPolicy)) {
    // has one, but not both
    const singularPolicy = (customPolicy || regionPolicy)!;
    return <Policy policy={singularPolicy} />;
  } else {
    return (
      <div className="flex flex-col items-stretch gap-4 overflow-hidden">
        <PolicyTitle slug={slug}>{title}</PolicyTitle>
        {/* Custom policies can't use handlebars */}
        <CompanyWidePolicyContent mainContent={customPolicy.content.main} />
        <AllRegionContent policy={regionPolicy} />
      </div>
    );
  }
};
