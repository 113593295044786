import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { NotFound } from './NotFound';
import { HandbookUnavailable } from './HandbookUnavailable';

export const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, [error]);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  return <HandbookUnavailable />;
};
