import { useUser } from '../../hooks/useUser';

export const HandbookTitle = () => {
  const { legal_entity } = useUser();
  return (
    <h1 className="flex flex-1 items-center font-semibold">
      <span className="hidden lg:inline">{legal_entity.name}&nbsp;</span>
      <span className="hidden sm:inline">Employee&nbsp;</span>
      Handbook
    </h1>
  );
};
