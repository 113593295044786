import {
  ActionFunctionArgs,
  json,
  redirect,
  useActionData,
} from 'react-router-dom';
import { NarrowWrapper } from '../../components/application/NarrowWrapper';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { AuthFooter } from './AuthFooter';
import { requestLink, resendLink } from '../../utils/auth';
import { RequestLink } from './RequestLink';
import { RequestNewLink } from './RequestNewLink';

type FormValuesRequestLink = {
  intent: typeof RequestLink.intent;
  email: string;
};

type FormValuesRequestNewLink = {
  email?: string;
  token: string;
  intent: typeof RequestNewLink.intent;
};

type FormValues = FormValuesRequestLink | FormValuesRequestNewLink;

type ActionData = {
  email?: string;
};

export const EmailSent = () => {
  const actionData = useActionData() as ActionData | undefined;
  return (
    <NarrowWrapper>
      <div>
        <h1 className="mb-2 text-4xl font-black tracking-tight text-zinc-800">
          Check your email
        </h1>
        <p className="text-zinc-800">
          A link has been sent to{' '}
          {actionData?.email ? (
            <strong>{actionData?.email}</strong>
          ) : (
            'your email address'
          )}
          , if it is a recognized email address.
        </p>
      </div>
      <p className="text-zinc-800">
        Didn&apos;t receive an email?
        <br />
        <TextLink to="/login">Try again</TextLink>
      </p>
      <AuthFooter />
    </NarrowWrapper>
  );
};

EmailSent.action = async ({ request }: ActionFunctionArgs) => {
  const data = (await request.json()) as FormValues;
  if (data.intent === RequestLink.intent) {
    try {
      await requestLink(data.email);
      return json({ email: data.email });
    } catch (err) {
      throw new Error('Something went wrong. Please try again.');
    }
  } else if (data.intent === RequestNewLink.intent) {
    try {
      await resendLink(data.token!);
      return json({ email: data.email });
    } catch (err) {
      return redirect('/login?error=resend-link');
    }
  }
  return redirect('/login');
};
