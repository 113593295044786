import {
  useSearchParams,
  useFetcher,
  useSubmit,
  useNavigation,
} from 'react-router-dom';
import { NarrowWrapper } from '../../components/application/NarrowWrapper';
import { Button } from '@mosey/components/buttons/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { TextField } from '@mosey/components/forms/TextField';
import { EmailPattern } from '@mosey/utils/validation/patterns';
import { AuthFooter } from './AuthFooter';

type FormValues = {
  email: string;
};

const INTENT = 'request-link' as const;

const ERRORS = {
  'invalid-token':
    'The link you followed is invalid or expired. Please enter your email below to request another.',
  'resend-link': 'There was a problem sending you a link. Please try again.',
};

export const RequestLink = () => {
  const submit = useSubmit();
  const { Form } = useFetcher();
  const { state } = useNavigation();
  const form = useForm<FormValues>();
  const [urlSearchParams] = useSearchParams();
  const errorCode = urlSearchParams.get('error');
  const error = ERRORS[errorCode as keyof typeof ERRORS];

  const onSubmit = (data: FormValues) => {
    submit(
      {
        ...data,
        intent: INTENT,
      },
      {
        method: 'POST',
        action: '/check-your-email',
        encType: 'application/json',
        navigate: true,
      },
    );
  };

  return (
    <FormProvider {...form}>
      <NarrowWrapper>
        <h1 className="text-4xl font-black tracking-tight text-zinc-800">
          Request a link to access the employee handbook
        </h1>
        <BlockAlert variant="error" message={error} show={!!error} />
        <Form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-start gap-4"
        >
          <TextField
            name="email"
            label="Work email address"
            placeholder="example@acme.com"
            error={form.formState.errors.email}
            reactFormConfig={{
              required: 'Email is required',
              pattern: EmailPattern,
            }}
            isFullWidth
          />
          <Button
            type="submit"
            isLoading={state !== 'idle'}
            disabled={state !== 'idle'}
          >
            Send me a link
          </Button>
        </Form>
        <AuthFooter />
      </NarrowWrapper>
    </FormProvider>
  );
};

RequestLink.intent = INTENT;
