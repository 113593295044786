import { LoaderFunctionArgs, matchPath, redirect } from 'react-router-dom';
import { api } from '../utils/api';
import { components } from '@mosey/api-types';

export async function userLoader({ request }: LoaderFunctionArgs) {
  const { pathname } = new URL(request.url);
  const response = await api.get({ url: `/api/handbook/users/me` });
  const user = (await response.json()) as components['schemas']['HandbookUser'];

  const hasAcknowledgedLegal = !!user.legal_notice_acknowledged_at;
  const isWelcome = !!matchPath('handbook/welcome', pathname);

  if (isWelcome && hasAcknowledgedLegal) {
    return redirect('/handbook');
  } else if (!isWelcome && !hasAcknowledgedLegal) {
    return redirect('/handbook/welcome');
  }

  return user;
}
